<template>
  <div id="blog-category-article-list">
    <div class="blog-cateory-name">
      <i class="el-icon-myblog-folder"></i>{{ categoryName }}
    </div>
    <blog-article-list-card
      v-for="(article, index) in articles"
      :key="index"
      :article="article"/>
    <el-pagination
      v-if="articlesTotal > 0"
      layout="prev, pager, next"
      :current-page.sync="currentPage"
      @current-change="handleCurrentChange"
      :page-size="pageSize"
      :total="articlesTotal">
    </el-pagination>
  </div>
</template>

<script>
import BlogArticleListCard from '@/components/BlogArticleListCard'

export default {
  name: 'blog-category-list',
  components: {
    BlogArticleListCard
  },
  data () {
    return {
      articles: [],
      articlesTotal: 0,
      currentPage: 1,
      pageSize: 5,
      categoryName: '分类'
    }
  },
  methods: {
    getArticlesList () {
      this.articles = []
      if (this.$route.params.categoryId) {
        this.$http({
          url: this.$api.BLOG_GET_ARTICLES_LIST_URL,
          method: 'GET',
          params: {
            categoryId: this.$route.params.categoryId,
            page: this.currentPage,
            pageSize: this.pageSize
          }
        }).then(response => {
          if (response.status === 'success') {
            this.articlesTotal = response.data.total
            for (const item of response.data.result) {
              this.articles.push(item)
            }
          } else {
            this.$message.error('服务器好像出错了...')
          }
        }).catch(() => {
          this.$message.error('哎呀...网络出错了，刷新一下吧')
        })
      } else {
        this.$router.push({ name: 'CategoriesIndex' })
      }
    },
    getCategory () {
      this.$http({
        url: this.$api.BLOG_GET_CATEGORY_COUNT_URL,
        method: 'GET',
        params: {
          categoryId: this.$route.params.categoryId
        }
      }).then(response => {
        if (response.status === 'success') {
          this.categoryName = response.data.result[0].name
        } else {
          this.$message.error('服务器好像出错了...')
        }
      }).catch(() => {
        this.$message.error('哎呀...网络出错了，刷新一下吧')
      })
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.getArticlesList()
    }
  },
  created () {
    this.getCategory()
    this.getArticlesList()
  }
}
</script>

<style lang="stylus" scoped>
.blog-cateory-name
  text-align: left
  font-size: 16pt
  margin-bottom: 20px
  font-weight: 600
  i
    margin-right: 10px
</style>
