<template>
  <div id="blog-article-list-card">
    <div class="blog-article-list-card-warp">
      <div
        @click="readArticle(article.articleId)"
        class="blog-article-title">
        {{ article.title }}
      </div>
      <div class="blog-article-info">
        <span><i class="el-icon-myblog-calendar"></i>发表于 {{ article.publishTime }}</span>
        <span
          @click="toCategory(article.category.categoryId)"
          class="blog-article-category">
              <i class="el-icon-myblog-folder"></i>
              {{ article.category.name }}
            </span>
        <span><i class="el-icon-myblog-browse"></i>阅读了 {{ article.pageviews }} 次</span>
      </div>
      <div class="blog-article-sub-message">{{ article.subMessage }}</div>
      <div class="blog-article-tags" v-if="article.tags.length > 0">
        <i class="el-icon-myblog-discount"></i>
        <a
          class="blog-article-tags-link"
          v-for="(tag, index) in article.tags"
          :key="index"
          @click="toTag(tag.tagId)">
          #{{ tag.name }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'blog-article-list-card',
  props: ['article'],
  data () {
    return {

    }
  },
  methods: {
    readArticle (articleId) {
      if (articleId) {
        this.$router.push({
          name: 'Article',
          params: { articleId: articleId }
        })
      }
    },
    toCategory (categoryId) {
      this.$router.push({
        name: 'CategoryArticleList',
        params: { categoryId: categoryId }
      })
    },
    toTag (tagId) {
      this.$router.push({
        name: 'TagArticleList',
        params: { tagId: tagId }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
#blog-article-list-card
  text-align: left
  position: relative
  padding: 20px
  margin-bottom: 20px
  box-shadow: 0 0 5px 0 rgba(38, 42, 48, .1)
  line-height: 1.2
  min-height: 160px
  &:last-child
    margin-bottom: 0
  @media (max-width: 768px)
    min-height: 151px
    padding: 10px
  .blog-article-list-card-warp
    position: relative
    .blog-article-title
      position: relative
      display: inline-block
      font-size: 16pt
      cursor: pointer
      font-weight: 600
      transition: all .3s
      @media (max-width: 768px)
        font-size: 14pt
      &:hover
        color: lighten(#4b4b4b, 20%)
    .blog-article-info
      margin: 10px 0
      font-size: 10pt
      @media (max-width: 768px)
        font-size: 8pt
        margin: 20px 0
        color: #5c5c5c
      span
        margin-right: 20px
        &:last-child
          margin-right: 0
        i
          margin-right: 5px
      .blog-article-category
        cursor: pointer
    .blog-article-sub-message
      color: #5c5c5c
      border-left: 3px solid #666
      padding-left: 10px
      margin: 15px 0
      font-size: 12pt
    .blog-article-tags
      color: #5c5c5c
      font-size: 10pt
      @media (max-width: 768px)
        font-size: 8pt
      margin: 15px 0 0 0
      i
        margin-right: 5px
      a
        margin: 0
        padding: 0
        span
          margin-left: -4px
      .blog-article-tags-link
        margin-right: 5px
        cursor: pointer
        &:last-child
          margin-right: 0
</style>
